export const environmentData = {
  run: "development",
  development: {
    clientId: "da898b05-0786-4346-bab4-ebf3ca4c3a11",
    authority:
      "https://login.microsoftonline.com/30f52344-4663-4c2e-bab3-61bf24ebbed8/oauth2/v2.0/token",
    tenantId: "30f52344-4663-4c2e-bab3-61bf24ebbed8",
    "api-scope":
      "openid da898b05-0786-4346-bab4-ebf3ca4c3a11/User.Impersonation",

    apiBaseURL: "https://hm-myntra-api-dev.azurewebsites.net/api",
  },
  staging: {
    clientId: "da898b05-0786-4346-bab4-ebf3ca4c3a11",
    authority:
      "https://login.microsoftonline.com/30f52344-4663-4c2e-bab3-61bf24ebbed8/oauth2/v2.0/token",
    tenantId: "30f52344-4663-4c2e-bab3-61bf24ebbed8",
    "api-scope":
      "openid da898b05-0786-4346-bab4-ebf3ca4c3a11/User.Impersonation",

    apiBaseURL: "https://hm-myntra-api-dev.azurewebsites.net/api",
  },
  production: {
    clientId: "da898b05-0786-4346-bab4-ebf3ca4c3a11",
    authority:
      "https://login.microsoftonline.com/30f52344-4663-4c2e-bab3-61bf24ebbed8/oauth2/v2.0/token",
    tenantId: "30f52344-4663-4c2e-bab3-61bf24ebbed8",
    "api-scope":
      "openid da898b05-0786-4346-bab4-ebf3ca4c3a11/User.Impersonation",
    apiBaseURL: "https://hm-myntra-api-dev.azurewebsites.net/api",
  },
};
